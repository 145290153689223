.readbtnsecond {

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
}

.readbtnsecond button {
  background-color: #3694C8;
  padding: 5px 15px;
  color: white;
  border-radius: 1rem;
  border: none;
}



.cardslidersetup {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
}

.spcialitiesContent {
  margin-bottom: 20px;

}

.spcialitiesContent hr {
  margin-bottom: 40px;
}

.spcialitiesContent p {
  font-size: 13px;
  margin: 0;
}

.gallerimages {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 0px;

}

.galleyig {
  width: 60%;
  border-radius: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.oneiggl {
  height: 280px;
  width: 280px;
  box-shadow: 0px 0px 4px black;
  margin: 20px 10px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 5px;

}

.oneiggl h6 {
  margin-top: 5px;

}

.oneiggl img {
  height: 100%;
  width: 100%;
  transition: .2s all ease-in-out;
  border-radius: 5px;


}

.oneiggl img:hover {
  transform: scale(1.1);
  transition: .2s all ease-in-out;

}

@media screen and (max-width:1400px) {
  .readbtnsecond button {
    background-color: #3694C8;
    /* padding: 5px 5px; */
    text-align: center;
    font-size: 12px;
    border-radius: 1rem;

  }

  .jkl1 {
    border-radius: 1rem;
    border: 2px solid gray;
    width: 31%;
    text-align: center;
    overflow: hidden;
    margin: 1rem 1rem;

  }

  .jkl {
    display: flex;
    margin: 1rem;
    margin-top: 30px;
  }

  .jkl img {
    height: 200px;
    width: 90%;
    margin-bottom: 2rem;
    margin: 1rem;
  }

  .cardslidersetup {
    margin-top: 10px;
    padding: 0px 0px;
  }

  .spcialitiesContent p {
    font-size: 13px;
    margin: 0;
  }
}

@media screen and (max-width:767px) {

  .readbtnsecond {
    margin-top: 20px;
    border-radius: 1rem;
    align-items: center;
  }

  .cardslidersetup {
    margin-top: 10px;
    align-items: initial;

  }

  .spcialitiesContent p {
    font-size: 13px;
    margin: 0;
  }
}



@media screen and (max-width:700px) {
  .readbtnsecond button {
    background-color: #3694C8;
    padding: 5px 12px;
    font-size: 12px;
    border-radius: 1rem;
  }

  .jkl {
    display: block;
    margin-top: 30px;
  }

  .jkl1 {
    border-radius: 1rem;
    border: 2px solid gray;
    width: 100%;
    text-align: center;
    overflow: hidden;
    margin: 1rem 1rem;
  }

  .jkl img {
    height: 400px;
    width: 90%;
    margin: 1rem;
  }

  .cardslidersetup {
    margin-top: 10px;
    padding: 0px 0px;
  }

  .spcialitiesContent p {
    font-size: 13px;
    margin: 0;
  }
}


@media screen and (max-width:900px) {
  .galleyig {
    width: 80%;

  }


}


@media screen and (max-width:767px) {

  .oneiggl {
    height: 250px;
    width: 250px;


  }


}

@media screen and (max-width:630px) {

  .oneiggl {
    height: 200px;
    width: 200px;


  }


}

@media screen and (max-width:550px) {

  .oneiggl {
    height: 260px;
    width: 260px;


  }

  .galleyig {
    flex-direction: column;
  }

  .jkl img {
    height: 350px;
    width: 90%;
    margin: 1rem;
  }


}

@media screen and (max-width:400px) {

  .oneiggl {
    height: 220px;
    width: 220px;


  }

  .jkl img {
    height: 300px;
    width: 90%;
    margin: 1rem;
  }



}

@media screen and (max-width:350px) {

  .oneiggl {
    height: 210px;
    width: 210px;


  }

  .jkl img {
    height: 250px;
    width: 90%;
    margin: 1rem;
  }



}

.logo {
  z-index: 9;
}
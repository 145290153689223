.App {
    font-family: sans-serif;
    text-align: center;
}

.dialog {
    box-shadow: 0 8px 6px -6px black;
    position: static;
    left: 20%;
    top: 10%;
}

.image {
    width: 600px;

}